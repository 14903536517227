




































import {Component, Vue} from 'vue-property-decorator';
import {Action} from 'vuex-class';
import router from '../router/index';
import {DialogProgrammatic as Dialog} from 'buefy';

const AUTH = {namespace: 'auth'};
const APP = {namespace: 'app'};

@Component({
    components: {},
})
export default class LoginPage extends Vue {
    @Action('login', AUTH) login: any;
    @Action('setActivePage', APP) a_setActivePage: any;
    @Action('getCSRF', AUTH) a_getCSRF: any;
    input: {username: string; password: string; xsrf: string};
    activeInputs: boolean[];

    constructor() {
        super();
        this.input = {
            username: '',
            password: '',
            xsrf: 'bfe6ad79-21d2-4684-8319-f45a9d442a22',
        };
        this.activeInputs = [false, false];
    }
    async loginUser(): Promise<void> {
        await this.login(this.input);
    }

    resetPw() {
        Dialog.confirm({
            title: 'Wachtwoord vergeten',
            message:
                'Deze optie herleidt u naar een pagina op het TopDesk portaal. Weet u het zeker?',
            type: 'is-info',
            confirmText: 'Ja',
            cancelText: 'Nee',
            trapFocus: true,

            onConfirm: () =>
                window.open(
                    'https://axians-ba.topdesk.net/passwordforgottenrequest?ispublic=true',
                    '_blank'
                ),
        });
    }

    navOldPortal() {
        window.location.assign('https://axians-ba.topdesk.net');
    }

    mounted(): void {
        // this.a_getCSRF();
        this.a_setActivePage('Login');
        window.addEventListener('keyup', (event) => {
            if (event.keyCode === 13) {
                // LoginPage.prototype.loginUser();
            }
        });
    }
}
