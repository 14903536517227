export const sleep = (seconds: number): Promise<number> => {
    return new Promise((resolve) => setTimeout(resolve, seconds * 1000));
};

/* Transform a flat guid into hyphenated uuid */
export const hyphenateUuid = (param: string): string => {
    let uuid = "";
    const segments = [8, 4, 4, 4, 12];

    segments.reduce((prev, curr, i) => {
        uuid += param.slice(prev, prev + curr)

        if (i !== segments.length - 1) {
            uuid += '-'
        }

        return prev + curr;
    }, 0)

    return uuid;
}
