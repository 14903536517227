// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../public/img/icons/error-404.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".page-wrapper[data-v-b4c4b066]{height:100vh;width:100%;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.error-message[data-v-b4c4b066]{font-family:Vinci Rounded Light,Arial,sans-serif;font-weight:700;font-size:100px;color:#fff}.back-to-home[data-v-b4c4b066]{margin-top:700px;margin-left:559px;border-radius:10px;width:auto;height:auto;border:none;background:#aa0469;padding:8px 10px 8px 10px;color:#fff;font-family:Vinci Rounded Regular,Arial,sans-serif;font-size:24px;font-weight:800px;text-decoration:none;cursor:pointer;opacity:1}.back-to-home[data-v-b4c4b066],.back-to-home[data-v-b4c4b066]:hover{-webkit-transition:.15s ease;transition:.15s ease}.back-to-home[data-v-b4c4b066]:hover{opacity:.9}", ""]);
// Exports
module.exports = exports;
