






import {Component, Vue, Emit} from 'vue-property-decorator';
@Component({name: 'NotFound'})
export default class NotFound extends Vue {
    navLanding() {
        this.$router.push('/landing');
    }
}
