import {MutationTree} from 'vuex';
import {AppState, Page} from './types';

export const mutations: MutationTree<AppState> = {
    ToggleNotifications(state): void {
        state.isIncidentsActive = !state.isIncidentsActive;
    },
    ToggleAccountMenu(state): void {
        state.isAccountMenuActive = !state.isAccountMenuActive;
    },
    SetActivePage(state, page: Page): void {
        state.activePage = page;
    },
    SetUnsavedChanges(state, changes: boolean) {
        state.unsavedChanges = changes;
    },
};
