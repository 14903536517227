import {Module} from 'vuex';
import {getters} from './getters';
import {actions} from './actions';
import {mutations} from './mutations';
import {AuthState} from './types';
import {RootState} from '../types';
import Cookie from 'js-cookie';

export const state: AuthState = {
    auth_status: '',
    personToken: Cookie.get('bisupport-session-header') || '',
    tdToken: Cookie.get('bisupport-token-header') || '',
    isLoggedIn: false,
    xsrfToken: '',
    user: {
        id: '',
        fax: '',
        email: '',
        jobTitle: '',
        creator: {id: '', name: ''},
        creationDate: '',
        branch: {id: '', name: ''},
    },
    sessionTimerRunning: false,
    error: false,
};

const namespaced = true;

export const AuthStore: Module<AuthState, RootState> = {
    namespaced,
    state,
    getters,
    actions,
    mutations,
};
