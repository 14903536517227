



























import {Action, Getter, Mutation} from 'vuex-class';
import {Component, Vue} from 'vue-property-decorator';
import {Page} from '../store/App/types';
import NotificationBell from '../components/NotificationBell.vue';
import {LogoutReason} from '../store/Auth/types';
import router from '../router/index';
// const INCIDENT = {namespace: 'incident'};
const AUTH = {namespace: 'auth'};
const APP = {namespace: 'app'};

@Component({
    name: 'Header',
    components: {NotificationBell},
})
export default class Header extends Vue {
    @Getter('activePage', APP) g_activePage: Page;
    @Getter('isAccountMenuActive', APP) g_accountMenuActive: boolean;
    @Action('toggleAccountMenu', APP) a_toggleAccountMenu: any;
    @Action('logout', AUTH) a_logout: any;
    @Mutation('SetUnsavedChanges', APP) m_setUnsavedChanges: any;
    title = 'Axians BI Servicedesk';

    constructor() {
        super();
    }
    get isLanding(): boolean {
        return this.g_activePage === 'Landing';
    }
    toggleAccount(): void {
        this.a_toggleAccountMenu();
    }
    navLanding(): void {
        if (this.g_activePage !== 'Landing') router.push('/landing');
    }
    navIncidents(): void {
        if (this.g_activePage !== 'Incidents')
            router.push('/melding/overzicht');
    }
    navProfile(): void {
        if (this.g_activePage !== 'UserSettings') router.push('/gebruiker');
    }
    logoutConfirm(): void {
        const answer = window.confirm('Weet u zeker dat u wilt uitloggen?');
        if (!answer) return;
        else {
            this.m_setUnsavedChanges(false);
            this.logout();
        }
    }
    logout(): void {
        this.a_logout('logout', LogoutReason.LOGOUT);
    }
}
