






























import 'reflect-metadata';
import {Action, Getter} from 'vuex-class';
import {Component, Vue, Prop} from 'vue-property-decorator';
import {
    Incident,
    IncidentMap,
    ProcessingStatus,
    IncidentSortingOptions,
} from '../store/Incidents/types';
import {sortDatesAsc, sortDatesDesc} from '../../../api/helpers/compareDates';
import IncidentCard from './IncidentCard.vue';
import {filter} from 'bluebird';
const INCIDENT = {namespace: 'incident'};
// const AUTH = {namespace: 'auth'};

@Component({name: 'IncidentList', components: {IncidentCard}})
export default class IncidentList extends Vue {
    @Prop() readonly incidents: IncidentMap;
    @Prop() readonly selectedSorting: IncidentSortingOptions;
    @Prop() readonly selectedStatusFilter: ProcessingStatus;
    @Prop() readonly displayClosed: boolean;
    @Getter('userIncidents', INCIDENT) g_userIncidents: IncidentMap;
    @Getter('activeIncidentId', INCIDENT)
    g_activeIncidentId: string;
    @Getter('activeIncident', INCIDENT)
    g_activeIncident: Incident;
    @Action('setActiveProgressTrail', INCIDENT)
    a_setActiveProgressTrail: any;
    @Action('fetchUserIncidents', INCIDENT)
    a_fetchUserIncidents: any;
    @Action('setActiveIncident', INCIDENT)
    a_setActiveIncident: any;
    @Action('setActiveIncidentId', INCIDENT)
    a_setActiveIncidentId: any;
    @Action('fetchProgressTrail', INCIDENT) a_fetchProgressTrail: any;
    @Action('fetchUserIncidentOperators', INCIDENT)
    a_fetchUserIncidentOperators: any;

    selectedIncident: Incident = null;
    isActive = false;
    newIncFormActive = false;
    incidentsShown = 10;
    incidentsIncrement = 10;
    minIncidents = 10;

    constructor() {
        super();
    }
    get atMinIncidents(): boolean {
        return this.incidentsShown <= this.minIncidents;
    }
    get atMaxIncidents(): boolean {
        return this.incidentsShown >= this.filteredIncidents.length;
    }
    get filteredIncidents(): Incident[] {
        if (this.incidents) {
            let filtered: Incident[] = [];
            // Check filter values
            if (!this.displayClosed) {
                filtered = [...this.incidents.values()].filter(
                    // toon alleen niet-afgesloten incidenten
                    (incident) => incident.closed === false
                );
            } else filtered = [...this.incidents.values()];
            return filtered || [];
        } else return [];
    }

    get sortedIncidents(): Incident[] {
        let sortedIncidents: Incident[] = [];
        if (this.selectedSorting === 'Laatst bijgewerkt') {
            sortedIncidents = sortDatesDesc(
                this.filteredIncidents,
                'modificationDate'
            );
        } else if (this.selectedSorting === 'Aanmelddatum nieuw-oud') {
            sortedIncidents = sortDatesDesc(this.filteredIncidents, 'callDate');
        } else if (this.selectedSorting === 'Aanmelddatum oud-nieuw') {
            sortedIncidents = sortDatesAsc(this.filteredIncidents, 'callDate');
        } else if (this.selectedSorting === 'Afmelddatum nieuw-oud') {
            sortedIncidents = sortDatesDesc(
                this.filteredIncidents,
                'closedDate'
            );
        } else if (this.selectedSorting === 'Afmelddatum oud-nieuw') {
            sortedIncidents = sortDatesAsc(
                this.filteredIncidents,
                'closedDate'
            );
        }
        return sortedIncidents.slice(0, this.incidentsShown) || [];
    }

    get incidentSelected(): boolean {
        return this.selectedIncident !== null;
    }
    // Methods
    async selectIncident(incId: string): Promise<void> {
        await this.a_fetchProgressTrail(this.incidents.get(incId).id);
        this.selectedIncident = this.incidents.get(incId);
    }
    get filterActive(): boolean {
        return this.displayClosed;
    }
    showMore(): void {
        this.incidentsShown += this.incidentsIncrement;
        this.$forceUpdate();
    }
    showLess(): void {
        this.incidentsShown -= this.incidentsIncrement;
        this.$forceUpdate();
    }
    // async mounted(): Promise<void> {
    //     this.a_setActiveIncidentId(null);
    // }
}
