





import {Component, Vue, Emit} from 'vue-property-decorator';

@Component({name: 'InternalError'})
export default class InternalError extends Vue {
    navLanding() {
        this.$router.push('/landing');
    }
}
