




































import {Action, Getter, Mutation} from 'vuex-class';
import {Component, Vue} from 'vue-property-decorator';
import {
    Incident,
    ProgressTrail,
    Attachment,
    IncidentMap,
} from '../store/Incidents/types';
import {User} from '../store/Auth/types';
import IncidentDetailBox from '../components/IncidentDetailBox.vue';
import Header from '../components/Header.vue';
import LoadingSpinner from '../components/LoadingSpinner.vue';
import router from '../router/index';
import {DialogProgrammatic as Dialog} from 'buefy';
import {hyphenateUuid} from "../store/utils";

export const TOPDESK_URL = "https://axians-ba.topdesk.net"

const INCIDENT = {namespace: 'incident'};
const AUTH = {namespace: 'auth'};
const APP = {namespace: 'app'};


Component.registerHooks(['beforeRouteLeave']);

@Component({
    name: 'IncidentDetail',
    components: {
        IncidentDetailBox,
        Header,
        LoadingSpinner,
    },
})
export default class IncidentDetail extends Vue {
    @Getter('activeIncident', INCIDENT) g_activeIncident: Incident;
    @Getter('userIncidents', INCIDENT) g_userIncidents: IncidentMap;
    @Getter('user', AUTH) g_user: User;
    @Getter('activeProgressTrail', INCIDENT) g_activePt: ProgressTrail;
    @Getter('userIncidentProgressTrails', INCIDENT) g_userProgressTrails: Map<
        string,
        ProgressTrail
    >;
    @Getter('unsavedChanges', APP) g_unsavedChanges: boolean;

    @Action('setActiveIncident', INCIDENT) a_setActiveIncident: any;
    @Action('fetchUserIncidents', INCIDENT) a_fetchUserIncidents: any;
    @Action('setActiveIncidentId', INCIDENT) a_setActiveIncidentId: any;
    @Action('fetchIncidentByNumber', INCIDENT) a_fetchIncidentByNumber: any;
    @Action('fetchProgressTrail', INCIDENT) a_fetchProgressTrail: any;
    @Action('fetchUserAccount', AUTH) a_fetchUserAccount: any;
    @Action('downloadAttachment', INCIDENT) a_downloadAttachment: any;
    @Action('navigationGuard', APP) a_navigationGuard: any;
    @Action('setActivePage', APP) a_setActivePage: any;

    @Mutation('SetActiveProgressTrail', INCIDENT) m_setActiveProgresstrail: any;

    isLoading = true;
    attActive = false;

    constructor() {
        super();
    }
    async beforeRouteLeave(to, from, next) {
        if (this.g_unsavedChanges) {
            await this.a_navigationGuard(next);
        } else {
            next();
        }
    }
    navNewIncident() {
        this.$router.push('/melding/aanmaken');
    }
    navOverview() {
        this.$router.push('/melding/overzicht');
    }
    get upperBd(): string {
        if (this.g_activeIncident) {
            return this.g_activeIncident.briefDescription.toUpperCase();
        }
    }

    get loading() {
        return this.isLoading;
    }

    get attachments() {
        const attachments = [...this.g_activePt.values()].filter((entry) => {
            return !!entry.fileName;
        });
        return attachments || [];
    }

    backToOverview() {
        this.$router.push('/melding/overzicht');
    }
    toggleAttActive() {
        this.attActive = !this.attActive;
    }

    download(url, fileName): void {
        const downloadUrl = `${TOPDESK_URL}${url}`;
        this.a_downloadAttachment({
            downloadUrl,
            fileName,
        });
    }

    async mounted() {
        this.a_setActivePage('IncidentDetail');
        if (!this.g_user.id) await this.a_fetchUserAccount();
        if (this.g_userIncidents.size == 0) await this.a_fetchUserIncidents();

        let redirectId = null;

        if (this.$route.query.unid) {
            const param = this.$route.query.unid as string;
            const number = this.g_userIncidents.get(hyphenateUuid(param)).number;

            if (!number) {
                this.$router.push("/melding/overzicht");
                return;
            } else {
                redirectId = number;
                this.$router.push("/melding/detail");
            }
        }

        const incidentIdStorage = redirectId || localStorage.getItem('activeIncident');
        this.a_setActiveIncidentId(incidentIdStorage);

        await this.a_fetchIncidentByNumber(incidentIdStorage);

        if (!this.g_userProgressTrails.get(this.g_activeIncident.id)) {
            await this.a_fetchProgressTrail(this.g_activeIncident.id);
        } else {
            this.m_setActiveProgresstrail(
                this.g_userProgressTrails.get(this.g_activeIncident.id)
            );
        }
        this.isLoading = false;
    }
}
