import {MutationTree} from 'vuex';
import {AuthState, User} from './types';

export const mutations: MutationTree<AuthState> = {
    auth_request(state): void {
        state.auth_status = 'loading';
    },
    auth_success(state, {personToken, tdToken}): void {
        state.auth_status = 'success';
        state.personToken = personToken;
        state.tdToken = tdToken;
    },
    auth_error(state): void {
        state.auth_status = 'error';
    },
    logout(state): void {
        state.auth_status = '';
        state.personToken = '';
        state.tdToken = '';
    },
    SetUserDetails(state, payload: User): void {
        state.user = payload;
    },
    SetTdToken(state, payload: string): void {
        state.tdToken = payload;
    },
    SetPersonToken(state, payload: string): void {
        state.personToken = payload;
    },
    SetLoggedIn(state, payload: boolean): void {
        state.isLoggedIn = payload;
    },
    SetXsrfToken(state, payload: string): void {
        state.xsrfToken = payload;
    },
    SetSessionTimerRunning(state, payload: boolean): void {
        state.sessionTimerRunning = payload;
    },
    ClearAuthState(state): void {
        state.personToken = '';
        state.tdToken = '';
        state.user = {
            id: '',
            fax: '',
            email: '',
            jobTitle: '',
            creator: {id: '', name: ''},
            creationDate: '',
            branch: {id: '', name: ''},
        };

        localStorage.removeItem('activeIncident');
    },
};
