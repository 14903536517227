import {GetterTree} from 'vuex';
import {IncidentState, Incident, ProgressTrail} from './types';
import {RootState} from '../types';

export const getters: GetterTree<IncidentState, RootState> = {
    userIncidents(state): Map<string, Incident> {
        const {userIncidents} = state;
        return userIncidents;
    },
    activeIncidentId(state): string {
        const {activeIncidentId} = state;
        return activeIncidentId;
    },
    activeIncident(state): Incident {
        return state.activeIncident;
    },
    newIncidentId(state): string {
        const {newIncidentId} = state;
        return newIncidentId;
    },
    userIncidentProgressTrails(state): Map<string, ProgressTrail> {
        return state.userIncidentProgressTrails;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    userIncidentAttachments(state): Map<string, any> {
        return state.userIncidentAttachments;
    },
    activeProgressTrail(state): ProgressTrail {
        return state.activeProgressTrail;
    },
    actionIncidentCount(state): number {
        return [...state.userIncidents.values()].filter(
            (inc): boolean =>
                inc.processingStatus.name === 'Wacht op info klant'
        ).length;
    },
    displayClosedIncidents(state): boolean {
        return state.displayClosedIncidents;
    },
};
