import {Module} from 'vuex';
import {getters} from './getters';
import {actions} from './actions';
import {mutations} from './mutations';
import {IncidentState} from './types';
import {RootState} from '../types';

export const state: IncidentState = {
    newIncidentId: '',
    userIncidents: new Map(),
    activeProgressTrail: new Map(),
    activeIncidentId: '',
    activeIncident: {
        request: '',
        briefDescription: '',
    },
    displayClosedIncidents: false,
    userIncidentProgressTrails: new Map(),
    userIncidentAttachments: new Map(),
    error: {error_state: false, error_message: null},
};

const namespaced = true;

export const IncidentStore: Module<IncidentState, RootState> = {
    namespaced,
    state,
    getters,
    actions,
    mutations,
};
