import Vue from 'vue';
import Vuex, {StoreOptions} from 'vuex';
import {RootState} from './types';
import {IncidentStore as incident} from './Incidents/index';
import {AuthStore as auth} from './Auth/index';
import {AppStore as app} from './App/index';

Vue.use(Vuex);

const store: StoreOptions<RootState> = {
    state: {
        version: undefined,
        sidebarActive: false,
    },
    modules: {
        incident,
        auth,
        app,
    },
};

export default new Vuex.Store<RootState>(store);
