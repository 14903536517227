import {ActionTree} from 'vuex';
import {RootState} from '../types';
import {AppState} from './types';
import {DialogProgrammatic as Dialog} from 'buefy';

export const actions: ActionTree<AppState, RootState> = {
    toggleNotifications({commit}): void {
        commit('ToggleNotifications');
    },
    toggleAccountMenu({commit}): void {
        commit('ToggleAccountMenu');
    },
    setActivePage({commit}, page: string): void {
        commit('SetActivePage', page);
    },
    async navigationGuard({commit, dispatch}, next: Function): Promise<void> {
        Dialog.alert({
            title: 'Navigatie',
            message:
                'U heeft openstaande wijzigingen, weet u zeker dat u de pagina wil verlaten?',
            confirmText: 'Pagina verlaten',
            cancelText: 'Terug',
            canCancel: true,
            onConfirm: () => {
                commit('app/SetUnsavedChanges', false, {root: true});
                next();
            },
            onCancel: () => next(false),
        });
    },
};
