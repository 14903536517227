import moment from 'moment';

/**
 * Neemt een array van objecten en een naam van een datumveld in de objecten als string.
 * Geeft de objecten terug in volgorde van oplopende datums (oud -> nieuw)
 */
export function sortDatesAsc(array: any[], dateFieldName: string) {
    const sortedArray = array;
    array.sort((a, b) => {
        // Prioritize not-null values in sorting
        if (a[dateFieldName] && !b[dateFieldName]) return -1;
        const m1 = moment(a[dateFieldName]);
        const m2 = moment(b[dateFieldName]);
        if (m1.isBefore(m2)) return -1;
        else if (m1.isAfter(m2)) return 1;
        else return 0;
    });
    return sortedArray;
}
/**
 * Neemt een array van objecten en een naam van een datumveld in de objecten als string.
 * Geeft de objecten terug in volgorde van aflopende datums (nieuw -> oud)
 */
export function sortDatesDesc(array: any[], dateFieldName: string) {
    const sortedArray = array;
    array.sort((a, b) => {
        // Prioritize not-null values in sorting
        if (a[dateFieldName] && !b[dateFieldName]) return -1;
        const m1 = moment(a[dateFieldName]);
        const m2 = moment(b[dateFieldName]);
        if (m1.isBefore(m2)) return 1;
        else if (m1.isAfter(m2)) return -1;
        else return 0;
    });
    return sortedArray;
}
