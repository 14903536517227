
































































































































import {Action, Getter, Mutation} from 'vuex-class';
import {Component, Vue} from 'vue-property-decorator';
import {User} from '../store/Auth/types';
import HeaderBar from '../components/Header.vue';
import LoadingSpinner from '../components/LoadingSpinner.vue';
import {ToastProgrammatic as Toast} from "buefy";

const AUTH = {namespace: 'auth'};
const APP = {namespace: 'app'};
// Validatie regex
const regexAlphaNum = new RegExp(/^[a-zA-Z0-9\s-]+$/);
const regexName = new RegExp(/^[a-z\s,\.'-]+$/i);
const regexPwd = new RegExp(/^([a-zA-Z,0-9@*#!&^$%()]{8,30})$/);
const regexPhone = new RegExp(
    /(^\+[0-9]{2}|^\+[0-9]{2}\(0\)|^\(\+[0-9]{2}\)\(0\)|^00[0-9]{2}|^0)([0-9]{9}$|[0-9\-\s]{9}$)/
);
const regexEmail = new RegExp(
    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*$/
);

//eslint-disable-next-line

// interface UserSettings {
//     firstName?: string;
//     lastName?: string;
//     title?: string;
//     telephone?: string;
//     mobileNumber?: string;
//     email?: string;k
//     faxNumber?: string;
//     branch?: string;
//     city?: string;
//     password?: string;
// }

@Component({
    name: 'UserSettings',
    components: {HeaderBar, LoadingSpinner},
})
export default class CreateIncident extends Vue {
    @Getter('user', AUTH) g_user: User;
    @Getter('unsavedChanges', APP) g_unsavedChanges: boolean;

    @Mutation('SetUnsavedChanges', APP) m_setUnsavedChanges: any;

    @Action('setActivePage', APP) a_setActivePage: any;
    @Action('fetchUserAccount', AUTH) a_fetchUserAccount: any;
    @Action('updateUser', AUTH) a_updateUser: any;
    @Action('checkLoginStatus', AUTH) a_checkLoginStatus: any;
    @Action('navigationGuard', APP) a_navigationGuard: any;

    constructor() {
        super();
    }

    async beforeRouteLeave(to, from, next) {
        // If saved changes, guard navigation
        // Exclude / for logout routes
        if (this.g_unsavedChanges && to.path !== '/') {
            await this.a_navigationGuard(next);
        } else {
            next();
        }
    }

    inputFields = [
        'Voornaam',
        'Achternaam',
        'Email',
        'Titel',
        'Telefoon',
        'Mobiel',
    ];
    startingInput: object;
    loading = true;
    changePassword = false;
    defaultInput: object = null;

    input = {
        Voornaam: {
            value: '',
            validation: false,
            required: true,
            validate: () =>
                regexName.test(this.Voornaam)
                    ? (this.input.Voornaam.validation = true)
                    : (this.input.Voornaam.validation = false),
            validationText:
                'Voornaam mag geen getallen of speciale tekens bevatten',
        },
        Achternaam: {
            value: '',
            validation: false,
            required: true,
            validate: () =>
                regexName.test(this.Achternaam)
                    ? (this.input.Achternaam.validation = true)
                    : (this.input.Achternaam.validation = false),
            validationText:
                'Achternaam mag geen getallen of speciale tekens bevatten',
        },
        Email: {
            value: '',
            validation: false,
            validate: () =>
                regexEmail.test(this.Email)
                    ? (this.input.Email.validation = true)
                    : (this.input.Email.validation = false),
            required: true,
            validationText: 'Ongeldig email adres',
        },
        Telefoon: {
            value: '',
            validate: () => {
                if (this.Telefoon) {
                    regexPhone.test(this.Telefoon)
                        ? (this.input.Telefoon.validation = true)
                        : (this.input.Telefoon.validation = false);
                } else this.input.Telefoon.validation = true;
            },

            validation: false,
            validationText: 'Ongeldig telefoon nummer',
        },
        Titel: {
            value: '',
            validation: false,
            validate: () => {
                if (this.Titel) {
                    regexAlphaNum.test(this.Titel)
                        ? (this.input.Titel.validation = true)
                        : (this.input.Titel.validation = false);
                } else this.input.Titel.validation = true;
            },
            validationText: 'Titel mag geen getallen bevatten',
        },
        Mobiel: {
            value: '',
            validation: false,
            validate: () => {
                if (this.Mobiel) {
                    regexPhone.test(this.Mobiel)
                        ? (this.input.Mobiel.validation = true)
                        : (this.input.Mobiel.validation = false);
                } else this.input.Mobiel.validation = true;
            },
            validationText: 'Ongeldig telefoon nummer',
        },
        Wachtwoord: {
            value: '',
            validation: false,
        },
        WachtwoordBevestig: {
            value: '',
            validation: false,
            required: !!this.Wachtwoord,
        },
    };

    get unsavedChanges() {
        // If user is changing password, add password fields to unsaved changes
        if (this.input['Wachtwoord'].value) {
            this.inputFields.concat(['Wachtwoord', 'WachtwoordBevestig']);
        }
        const changes = this.inputFields.filter((el) => {
            return this.input[el].value !== this.startingInput[el].value;
        });
        return changes.length > 0;
    }

    checkUnsavedChanges() {
        this.m_setUnsavedChanges(this.unsavedChanges);
    }

    get saveButtonEnabled() {
        if (this.changePassword && this.passwordVal) return true;
        else if (!this.changePassword) return true;
        else return false;
    }
    get passwordVal() {
        if (this.Wachtwoord.length > 0 && this.passwordEq) {
            return regexPwd.test(this.Wachtwoord);
            // Als Wachtwoord veld leeg is, altijd valideren
        } else return true;
    }
    get passwordEq() {
        if (this.Wachtwoord.length === this.WachtwoordBevestig.length) {
            return this.Wachtwoord === this.WachtwoordBevestig;
            // Als wachtwoord leeg is, altijd gelijk
        } else return false;
    }
    get Voornaam() {
        if (this.input) return this.input.Voornaam.value;
        else return '';
    }
    get Achternaam() {
        if (this.input) return this.input.Achternaam.value;
        else return '';
    }
    get fullName() {
        if (this.input) {
            const {firstName, surName} = this.g_user;
            return `${firstName} ${surName}`;
        } else return '';
    }
    get Email() {
        if (this.input) return this.input.Email.value;
        else return '';
    }
    get branch() {
        if (this.g_user) return this.g_user.branch.name;
        else return '';
    }
    get Titel() {
        if (this.input) return this.input.Titel.value;
        else return '';
    }
    get Telefoon() {
        if (this.input) return this.input.Telefoon.value;
        else return '';
    }
    get Mobiel() {
        if (this.input) return this.input.Mobiel.value;
        else return '';
    }
    get Wachtwoord() {
        if (this.input) return this.input.Wachtwoord.value;
        else return '';
    }
    get city() {
        if (this.g_user) return this.g_user.city;
        else return '';
    }
    get WachtwoordBevestig() {
        if (this.input) return this.input.WachtwoordBevestig.value;
        else return '';
    }
    get formValid() {
        const {Voornaam, Achternaam, Email} = this.input;
        if (!Voornaam.value || !Achternaam.value || !Email.value) return false;
        if (!this.passwordVal || !this.passwordEq) return false;

        const validated = Object.values(this.input).filter((val) => {
            return val.validation;
        });
        // Length -3 voor titel, mobiel, telefoon die niet gevalideerd worden
        if (validated.length < this.inputFields.length) return false;
        else return true;
    }
    set Voornaam(value: string) {
        this.input.Voornaam.value = value;
    }
    set Achternaam(value: string) {
        this.input.Achternaam.value = value;
    }
    set Email(value: string) {
        this.input.Email.value = value;
    }
    set Titel(value: string) {
        this.input.Titel.value = value;
    }
    set Telefoon(value: string) {
        this.input.Telefoon.value = value;
    }
    set Mobiel(value: string) {
        this.input.Mobiel.value = value;
    }
    set Wachtwoord(value: string) {
        this.input.Wachtwoord.value = value;
    }
    set WachtwoordBevestig(value: string) {
        this.input.WachtwoordBevestig.value = value;
    }
    cancel(): void {
        this.$router.push('/landing');
    }
    setInputField(value: string, label: string): void {
        this[label] = value;
    }
    validateInputField(value: string, label: string): void {
        this.input[label].validate();
    }
    validateInputFields() {
        this.inputFields.forEach((label) =>
            this.validateInputField(null, label)
        );
    }
    setDefaultInputs() {
        this.input.Voornaam.value = this.g_user.firstName;
        this.input.Achternaam.value = this.g_user.surName;
        this.input.Email.value = this.g_user.email;
        this.input.Telefoon.value = this.g_user.phoneNumber;
        this.input.Titel.value = this.g_user.jobTitle;
        this.input.Mobiel.value = this.g_user.mobileNumber;
        this.input.Wachtwoord.value = '';
        this.input.WachtwoordBevestig.value = '';
        this.startingInput = JSON.parse(JSON.stringify(this.input));
    }

    async updateUser(): Promise<void> {
        if (await this.a_checkLoginStatus()) {
            let user;
            if (this.Wachtwoord && this.WachtwoordBevestig && this.formValid) {
                user = {
                    firstName: this.Voornaam,
                    surName: this.Achternaam,
                    email: this.Email,
                    jobTitle: this.Titel,
                    phoneNumber: this.Telefoon,
                    mobileNumber: this.Mobiel,
                    password: this.Wachtwoord,
                };
            } else if (
                !this.Wachtwoord &&
                !this.WachtwoordBevestig &&
                this.formValid
            ) {
                user = {
                    firstName: this.Voornaam,
                    surName: this.Achternaam,
                    email: this.Email,
                    jobTitle: this.Titel,
                    phoneNumber: this.Telefoon,
                    mobileNumber: this.Mobiel,
                };
            } else {
                window.alert(
                    'Formulier ongeldig, controlleer of alle velden zijn ingevuld'
                );
                return;
            }

            await this.a_updateUser(user);
            
            this.input.Wachtwoord.value = '';
            this.input.WachtwoordBevestig.value = '';
            this.checkUnsavedChanges();
            this.setDefaultInputs();

            // this.$forceUpdate();
        }
    }

    async mounted(): Promise<void> {
        this.a_setActivePage('UserSettings');

        await this.a_fetchUserAccount();
        this.setDefaultInputs();
        this.validateInputFields();

        this.loading = false;
    }
}
