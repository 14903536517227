
















































import {Action, Getter} from 'vuex-class';
import {Component, Vue} from 'vue-property-decorator';
import {Incident} from '../store/Incidents/types';
import {User} from '../store/Auth/types';
import Header from '../components/Header.vue';
import IncidentList from '../components/IncidentList.vue';
import router from '../router/index';
const INCIDENT = {namespace: 'incident'};
const AUTH = {namespace: 'auth'};
const APP = {namespace: 'app'};

@Component({
    name: 'LandingPage',
    components: {
        Header,
        IncidentList,
    },
})
export default class LandingPage extends Vue {
    @Getter('user', AUTH) g_user: User;
    @Getter('incidentsActive', APP)
    g_incidentsActive: boolean;
    @Getter('isAccountMenuActive', APP)
    g_isAccountMenuActive: boolean;
    @Getter('userIncidents', INCIDENT)
    g_userIncidents: Map<string, Incident>;
    @Action('setActivePage', APP)
    a_setActivePage: any;
    @Action('fetchUserIncidents', INCIDENT)
    a_fetchUserIncidents: any;
    @Action('fetchUserAccount', AUTH)
    a_fetchUserAccount: any;

    actions: string[] = ['Incidents', 'Knowledgebase', 'Training'];
    incidentMethods: string[] = ['Portal', 'Email', 'Phone'];
    input = '';
    data: string[] = [
        'Melding1',
        'Melding2',
        'Qlik Sense Melding 1',
        'BO Melding 1',
        'BODS Melding',
    ];
    incidentsActive = false;
    dummyData = {
        naam: 'E. Derksen',
        melding: 'M.2020.02.20',
        status: 'extra informatie',
    };

    constructor() {
        super();
    }
    get searchInput() {
        return this.input;
    }
    get openIncidentCount() {
        return [...this.g_userIncidents.values()].filter(
            (inc) => inc.closed === false
        ).length;
    }
    get actionIncidentCount() {
        return [...this.g_userIncidents.values()].filter(
            (inc) => inc.processingStatus.name === 'Wacht op info klant'
        ).length;
    }
    get accountActive() {
        return this.g_isAccountMenuActive;
    }
    toggleIncidents() {
        this.incidentsActive = !this.incidentsActive;
    }
    navCreate() {
        router.push('/melding/aanmaken');
    }
    navOverview() {
        router.push('melding/overzicht');
    }
    async mounted() {
        this.a_setActivePage('Landing');
        if (!this.g_user.id) await this.a_fetchUserAccount();
        if (this.g_userIncidents.size == 0) await this.a_fetchUserIncidents();
    }
}
