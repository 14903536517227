




















import {Action} from 'vuex-class';
import {Component, Vue, Prop} from 'vue-property-decorator';
import {
    Action as PTAction,
    Attachment,
    Email,
    IncidentObj,
} from '../store/Incidents/types';
import moment from 'moment';
const INCIDENT = {namespace: 'incident'};
const AUTH = {namespace: 'auth'};
const TOPDESK_URL = "https://axians-ba.topdesk.net"
// const AUTH = {namespace: 'auth'};

@Component({
    name: 'ActionCard',
})
export default class ActionCard extends Vue {
    @Prop(Object) pt: PTAction;
    @Prop(String) activeIncidentId: string;
    @Action('downloadAttachment', INCIDENT) a_downloadAttachment: any;
    @Action('checkLoginStatus', AUTH) a_checkLogin: any;

    constructor() {
        super();
    }
    get ptType(): string {
        if (this.pt) {
            if ((this.pt as Attachment).downloadUrl) {
                return 'Attachment';
            } else if ((this.pt as Email).title) {
                return 'Email';
            } else return 'Text';
        }
    }
    get fullDownloadUrl(): string {
        if (this.pt) {
            if ((this.pt as Attachment).downloadUrl) {
                const attachment: Attachment = this.pt as Attachment;
                return `${TOPDESK_URL}${attachment.downloadUrl}`;
            }
        }
    }
    get actionUser(): IncidentObj {
        if (this.pt.operator !== null) {
            return this.pt.operator;
        } else if (this.pt.person !== null) {
            return this.pt.person;
        }
    }
    get entryDate(): string {
        if (this.pt) {
            return moment(this.pt.entryDate)
                .locale('nl')
                .format('LLL');
        }
    }
    async download(): Promise<void> {
        if(await this.a_checkLogin()){
            this.a_downloadAttachment({
                downloadUrl: this.fullDownloadUrl,
                fileName: (this.pt as Attachment).fileName,
            });
        }
    }
}
