// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../public/img/Background-create2.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".incident-detail-wrapper[data-v-174dcb7b]{width:100vw;height:100vh;background:#fafafa;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-size:cover;background-position:top}.click-back-box-left[data-v-174dcb7b]{position:fixed;height:90vh;width:100vw}.detail-box[data-v-174dcb7b]{margin:0 auto;position:relative;top:2.5vh}.right[data-v-174dcb7b]{width:100%;position:relative}.right[data-v-174dcb7b]:hover{opacity:.7;cursor:pointer;-webkit-transition:.15s;transition:.15s}.left[data-v-174dcb7b]{float:left;margin-right:1vw}.hl[data-v-174dcb7b]{margin-top:.5vw;border-top:1px solid rgba(85,85,85,.33)}.label-back[data-v-174dcb7b]{font-size:14px;color:#a20067;position:absolute;right:25vw;bottom:2.5vh;-webkit-transition:.25s;transition:.25s}.attachment-txt[data-v-174dcb7b]{font-family:Vinci Rounded Light,Arial,sans-serif;font-size:1.1rem;font-weight:500;margin:0}.label-back[data-v-174dcb7b]:hover{cursor:pointer;opacity:.7;-webkit-transition:.25s;transition:.25s}.incident-detail-attachmentbox[data-v-174dcb7b]{right:11vw;top:16vh;background-color:#f5f5f5;position:fixed;height:auto;width:12%;border:1px solid #e9e9e9;border-radius:10px;margin:0 auto;padding:12px 20px;-webkit-transition:1.2s;transition:1.2s}h1.spinner-title[data-v-174dcb7b]{top:37vh;left:40.7vw;position:absolute;z-index:999;color:#fff;font-weight:700;font-size:30px}@media only screen and (max-width:600px){.incident-detail-wrapper[data-v-174dcb7b]{height:100vh;width:100vw;overflow:hidden}.detail-box[data-v-174dcb7b]{top:0}}", ""]);
// Exports
module.exports = exports;
