import Vue from 'vue';
import VueRouter, {RawLocation} from 'vue-router';
import Login from '../views/Login.vue';
import Incidents from '../views/Incidents.vue';
import Landing from '../views/Landing.vue';
import CreateIncident from '../views/CreateIncident.vue';
import IncidentDetail from '../views/IncidentDetail.vue';
import UserSettings from '../views/UserSettings.vue';
import NotFound from '../components/NotFound.vue';
import Maintenance from "../components/Maintenance.vue";
import InternalError from '../components/InternalError.vue';
import {state as AuthState} from '../store/Auth/index';
import store from '../store/index';
import {LogoutReason} from '../store/Auth/types';

const originalPush = VueRouter.prototype.push;

// Swallow navigation duplicated errors
VueRouter.prototype.push = function push(location: RawLocation) {
    return originalPush.call(this, location).catch((err) => {
        if (err.name !== 'NavigationDuplicated') throw err;
    });
};

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'login',
        component: Login,
        // redirect: {name: 'Maintenance'}
    },
    {
        path: '/tas/public/contained/incidentpublic',
        name: 'redirectFromMail',
        redirect: {name: 'Incident Detail'}
    },
    {
        path: '/tas/secure',
        name: 'redirectFromOldBookmark',
        redirect: {name: 'login'}
    },
    {
        path: '/landing',
        name: 'landing',
        component: Landing,
        meta: {requiresAuth: true},
    },
    {
        path: '/melding/overzicht',
        name: 'incidents',
        component: Incidents,
        meta: {requiresAuth: true},
    },
    {
        path: '/melding/aanmaken',
        name: 'Create Incident',
        component: CreateIncident,
        meta: {requiresAuth: true},
    },
    {
        path: '/melding/detail',
        name: 'Incident Detail',
        component: IncidentDetail,
        meta: {requiresAuth: true},
    },
    {
        path: '/gebruiker',
        name: 'User Settings',
        component: UserSettings,
        meta: {requiresAuth: true},
    },
    {
        path: '/error',
        name: 'Internal Error',
        component: InternalError,
    },
    // {
    //     path: '/maintenance',
    //     name: 'Maintenance',
    //     component: Maintenance
    // },
    {
        path: '*',
        name: 'Niet Gevonden',
        component: NotFound,
    },
];

const router = new VueRouter({
    mode: 'history',
    routes,
});

router.beforeEach(
    async (to, from, next): Promise<void> => {
        // Handle routes that require Authentication
        if (to.matched.some((record): void => record.meta.requiresAuth)) {
            const loggedIn = await store.dispatch(
                'auth/checkLoginStatus',
                {},
                {root: true}
            );
            
            if (loggedIn) {
                next();
            } 

        } else {
            next();
        }

        // Automatically send a logged in user from / to /landing
        if (to.matched.some((record) => record.name === 'login')) {
            if (AuthState.personToken && AuthState.tdToken) {
                next({
                    path: '/landing',
                });
            }
        }
    }
);

export default router;
