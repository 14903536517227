





























































































import {Component, Vue, Emit} from 'vue-property-decorator';
import {IncidentObj, NewIncident, IncidentMap} from '../store/Incidents/types';
import {Action, Getter, Mutation} from 'vuex-class';

interface Priority {
    prio: number;
    name: string;
    selected: boolean;
}

const INCIDENT = {namespace: 'incident'};
const AUTH = {namespace: 'auth'};
const APP = {namespace: 'app'};
/* eslint-disable @typescript-eslint/no-var-requires */
const impacts = require('../assets/impacts.json');
const urgencies = require('../assets/urgencies.json');

@Component({
    name: 'NewIncidentForm',
    components: {},
})
export default class NewIncidentForm extends Vue {
    @Getter('isLoggedIn', AUTH) g_isLoggedIn: boolean;
    @Getter('userId', AUTH) g_userId: string;
    @Getter('newIncidentId', INCIDENT) g_newIncidentId: string;
    @Getter('userIncidents', INCIDENT) g_userIncidents: IncidentMap;

    @Action('storeIncident', INCIDENT) a_storeIncident: any;
    @Action('uploadAttachment', INCIDENT) a_uploadAttachment: any;
    @Action('fetchUserAccount', AUTH) a_fetchUserAccount: any;
    @Action('postDefaultActions', INCIDENT) a_postDefaultActions: any;
    @Action('logout', AUTH) a_logout: any;
    @Action('fetchProgressTrail', INCIDENT) a_fetchProgressTrail: any;
    @Action('checkLoginStatus', AUTH) a_checkLoginStatus: any;

    @Mutation('SetActiveIncident', INCIDENT) m_setActiveIncident: any;
    @Mutation('SetUnsavedChanges', APP) m_setUnsavedChanges: any;

    constructor() {
        super();
    }

    input = {
        shortDescription: '',
        initialRequest: '',
        priority: '',
    };

    priorities: Priority[] = [
        {prio: 1, name: 'Prioriteit 1', selected: false},
        {prio: 2, name: 'Prioriteit 2', selected: false},
        {prio: 3, name: 'Prioriteit 3', selected: false},
        {prio: 4, name: 'Prioriteit 4', selected: false},
    ];
    impactList: IncidentObj[] = impacts;
    urgencyList: IncidentObj[] = urgencies;

    selectedImpact = '';
    selectedUrgency = '';
    selectedPriority = '';
    files: File[] = [];
    dragActive = false;
    isSubmitting = false;

    get userIncidents() {
        return this.g_userIncidents;
    }
    get isPrioOne() {
        return this.priorities[0].selected;
    }
    get shortDesc() {
        if (this.input) return this.input.shortDescription;
        else return '';
    }
    get unsavedChanges() {
        const changes =
            this.input.shortDescription ||
            this.input.initialRequest ||
            this.input.priority;
        this.m_setUnsavedChanges(changes);
        return changes;
    }
    get initialRequest() {
        if (this.input) return this.input.initialRequest;
        else return '';
    }
    get actionReady() {
        return (
            !!this.shortDesc &&
            !!this.initialRequest &&
            !!this.selectedPriority &&
            !this.bdUpperLimit
        );
    }
    get prioImpactUrgency() {
        if (this.selectedPriority) {
            switch (this.selectedPriority) {
                case 'Prioriteit 1':
                    this.selectedUrgency =
                        '81fbcd0a-d0cb-567e-80ee-c0eb0dda3e2f';
                    this.selectedImpact =
                        'ad3b49d0-d175-55b7-8e02-74495f258527';
                    break;
                case 'Prioriteit 2':
                    this.selectedUrgency =
                        'aac1e421-6a21-5ab8-878b-61f1c8e6187b';
                    this.selectedImpact =
                        '9496e872-39c7-54cb-8682-870a9647bd4c';
                    break;
                case 'Prioriteit 3':
                    this.selectedUrgency =
                        'aac1e421-6a21-5ab8-878b-61f1c8e6187b';
                    this.selectedImpact =
                        '363f2386-63c2-515b-8beb-39aea7860c91';
                    break;
                case 'Prioriteit 4':
                    this.selectedUrgency =
                        '3d1789ec-f3b0-55da-8f06-8d5deb5340bb';
                    this.selectedImpact =
                        '363f2386-63c2-515b-8beb-39aea7860c91';
                    break;
            }
        }
        return {
            priority: this.selectedPriority,
            urgency: this.selectedUrgency,
            impact: this.selectedImpact,
        };
    }
    set shortDesc(value: string) {
        this.input.shortDescription = value;
    }
    set initialRequest(value: string) {
        this.input.initialRequest = value;
    }
    clearFile() {
        this.files = null;
    }
    get bdUpperLimit(): boolean {
        return this.shortDesc.length > 80;
    }
    activateDrag() {
        this.dragActive = true;
    }

    leaveDrag() {
        this.dragActive = false;
    }

    dropthatass(e) {
        e.preventDefault();
        if (e && e.dataTransfer.files) {
            for (const file of e.dataTransfer.files) {
                this.files.push(file);
            }
            this.dragActive = false;
        }
    }

    selectImpactValue(value: string) {
        this.selectedImpact = value;
    }
    selectPriorityValue(value: Priority) {
        this.selectedPriority = value.name;
        this.priorities.map((el) => {
            el.selected = false;
        });
        this.priorities[value.prio - 1].selected = true;
    }
    navOverview(): void {
        this.$emit('nav-overview');
    }
    deleteFile(index: number) {
        this.files.splice(index, 1);
    }

    async createIncident() {
        if (await this.a_checkLoginStatus()) {
            this.m_setUnsavedChanges(false);
            this.isSubmitting = true;

            const {urgency, priority, impact} = this.prioImpactUrgency;
            const entryType = "390e6bc8-639b-4abc-8aed-8500e4ea25e4" // BI Portaal
            const fmt = this.input.initialRequest.replace(/\n/g, '</br>');

            const newIncident: NewIncident = {
                briefDescription: this.input.shortDescription,
                request: fmt,
                priority: {name: priority},
                urgency: {id: urgency},
                impact: {id: impact},
                entryType: {id: entryType}
            };

            const userId = this.g_userId;

            await this.a_storeIncident({newIncident, userId});

            const createdIncident = this.userIncidents.get(
                this.g_newIncidentId
            );

            localStorage.setItem('activeIncident', createdIncident.number);

            if (createdIncident) {
                this.m_setActiveIncident(createdIncident);
                this.$router.push(`/melding/detail`);
                if (this.files) {
                    for (const file of this.files) {
                        const formData = new FormData();
                        formData.append('file', file);
                        formData.append('invisibleForCaller', 'false');
                        formData.append('description', 'test description');
                        this.a_uploadAttachment({
                            // file: formData,
                            file: formData,
                            id: createdIncident.id,
                        });
                    }
                }
                this.a_postDefaultActions(createdIncident.id);
                // await this.a_fetchProgressTrail(createdIncident.id);
                this.clearInputFields();
            }
            this.isSubmitting = false;
        }
    }
    clearInputFields() {
        this.input = {
            shortDescription: '',
            initialRequest: '',
            priority: '',
        };
        // Form validation to prevent sending default values with the request
        this.selectedImpact = 'Selecteer de impact';
    }

    mounted() {
        const ref = localStorage.getItem('referenceIncident');
        if (ref) {
            const parsedRef = JSON.parse(ref);
            this.input.shortDescription = `Voortzetting van melding: ${parsedRef.number} \n`;
            localStorage.removeItem('referenceIncident');
        }
    }
}
