import {Module} from 'vuex';
import {getters} from './getters';
import {actions} from './actions';
import {mutations} from './mutations';
import {AppState} from './types';
import {RootState} from '../types';

export const state: AppState = {
    isIncidentsActive: false,
    isAccountMenuActive: false,
    activePage: undefined,
    unsavedChanges: false,
};

const namespaced = true;

export const AppStore: Module<AppState, RootState> = {
    namespaced,
    state,
    getters,
    actions,
    mutations,
};
