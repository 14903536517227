import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router/index';
// import {store as oldStore} from "./store/index_old";
import store from './store/index';
import Buefy from 'buefy';
import 'buefy/dist/buefy.css';
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
import * as Sentry from "@sentry/browser";
import { Vue as VueIntegration } from "@sentry/integrations";
import { Integrations } from '@sentry/tracing';

Vue.use(Buefy);
Vue.use(Loading);

// if (process.env.NODE_ENV === "production") {
//     Sentry.init({
//         dsn: "https://aa209a663cdb4d9c97535ce0e52ea186@o94466.ingest.sentry.io/5425756",
//         integrations: [
//           new VueIntegration({
//             Vue,
//             tracing: true
//           }),
//           new Integrations.BrowserTracing()
//         ],
//         tracesSampleRate: 1
//     });
// }

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount('#app');
