












































































































































import {Component, Vue, Prop, Emit} from 'vue-property-decorator';
import {Incident, ProgressTrail, IncidentMap} from '../store/Incidents/types';
import {User, LogoutReason} from '../store/Auth/types';
import {Action, Getter, Mutation} from 'vuex-class';
import ActionCard from '../components/ActionCard.vue';
import moment from 'moment';
import {sortDatesAsc, sortDatesDesc} from '../../../api/helpers/compareDates';
import {ToastProgrammatic as Toast} from 'buefy';
import {DialogProgrammatic as Dialog} from 'buefy';
import sanitizeHtml from 'sanitize-html';

const TOPDESK_URL = "https://axians-ba.topdesk.net"
const INCIDENT = {namespace: 'incident'};
const AUTH = {namespace: 'auth'};
const APP = {namespace: 'app'};


interface HeaderItem {
    title: string;
    body: string;
}

const sanitizeConfig = {
    allowedTags: ['i', 'em', 'b', 'strong', 'br'],
    allowedAttributes: [],
    selfClosing: ['br'],
};

@Component({
    name: 'IncidentDetailBox',
    components: {ActionCard},
})
export default class IncidentDetail extends Vue {
    @Prop(Object) p_incident: Incident;

    @Getter('activeProgressTrail', INCIDENT) g_progressTrail: ProgressTrail;
    @Getter('activeIncident', INCIDENT) g_activeIncident: Incident;

    @Getter('user', AUTH) g_user: User;
    @Mutation('SetUnsavedChanges', APP) m_setUnsavedChanges: any;
    @Action('closeIncident', INCIDENT) a_closeIncident: any;

    @Action('rateIncident', INCIDENT) a_rateIncident: any;
    @Action('sendNewAction', INCIDENT) a_sendNewAction: any;
    @Action('fetchIncidentByNumber', INCIDENT) a_fetchIncidentByNumber: any;
    @Action('uploadAttachment', INCIDENT) a_uploadAttachment: any;
    @Action('downloadAttachment', INCIDENT) a_downloadAttachment: any;
    @Action('fetchProgressTrail', INCIDENT) a_fetchProgressTrail: any;
    @Action('checkLoginStatus', AUTH) a_checkLoginStatus: any;
    @Action('logout', AUTH) a_logout: any;
    @Action('navigationGuard', APP) a_navigationGuard: any;
    // @Action('fetchProgressTrail', INCIDENT) a_fetchProgressTrail: any;
    // @Action('fetchUserIncidents', INCIDENT) a_fetchUserIncidents: any;
    // @Action('fetchUserAccount', AUTH)

    ratingActive = false;
    selectedRating = 1;
    feedbackMessageInput = '';
    isSending = true;

    files: File[] = [];

    newMessage = '';
    isUploading = false;
    dragactive = false;

    constructor() {
        super();
    }

    navNewIncident() {
        this.checkUnsavedChanges();
        const reference = JSON.stringify({
            number: this.p_incident.number,
            description: this.p_incident.branch
        })

        localStorage.setItem("referenceIncident", reference)

        this.$emit('nav-new-incident');
    }
    navOverview() {
        this.checkUnsavedChanges();
        this.$emit('nav-overview');
    }
    checkUnsavedChanges() {
        const changes = this.unsavedChanges;
        this.m_setUnsavedChanges(changes);
    }
    async refreshPage() {
        await Promise.all([
            this.a_fetchProgressTrail(this.p_incident.id),
            this.a_fetchIncidentByNumber(this.p_incident.number),
        ]);
        this.$forceUpdate();
    }

    get unsavedChanges() {
        const unsavedChanges =
            !!this.newMessage || (this.files && this.files.length > 0);
        return unsavedChanges;
    }
    get submitButtonText() {
        if (this.files && this.files.length > 0 && !this.newMessage)
            return 'Bijlagen toevoegen';
        else return 'Versturen';
    }
    // get files() {
    //     if(this.addFiles && this.dropFiles) return this.addFiles.concat(this.dropFiles)
    //     else if(!this.addFiles && this.dropFiles) return this.dropFiles
    //     else if(this.addFiles && this.dropFiles) return this.addFiles
    //     else return [];
    // }

    get incident() {
        if (this.p_incident) return this.p_incident;
        else return this.g_activeIncident;
    }
    get upperBd() {
        if (this.incident && this.incident.briefDescription) {
            return sanitizeHtml(this.incident.briefDescription, sanitizeConfig);
        } else return '';
    }
    get isClosed() {
        if (this.incident.closed) return this.incident.closed;
        else return '';
    }

    get trimmedRequest() {
        if (this.incident && this.incident.request) {
            const request = this.incident.request 
            return sanitizeHtml(request, sanitizeConfig);
        } else return '';
    }
    get closeButtonText() {
        return this.isClosed ? 'Melding afgesloten' : 'Sluit melding';
    }
    get callDate() {
        if (this.incident && this.incident.callDate) {
            return this.incident.callDate;
        } else return '';
    }
    get number() {
        if (this.incident && this.incident.number) return this.incident.number;
        else return '';
    }
    get priority() {
        if (this.incident && this.incident.priority)
            return this.incident.priority.name;
        else return '';
    }
    get processingStatus() {
        if (this.incident && this.incident.processingStatus)
            return this.incident.processingStatus.name;
        else return '';
    }
    get operator() {
        if (this.incident) {
            if (!this.incident.operator) return 'Niet toegewezen';
            else return this.incident.operator.name;
        }
    }
    get dynamicName() {
        if (this.incident && this.incident.caller) {
            return this.incident.caller.dynamicName;
        } else return '';
    }
    get caller() {
        if (this.incident && !this.incident.caller) return 'Niet toegewezen';
        else return this.incident.operator.name;
    }
    get placeholderMessage() {
        if (this.isClosed) return 'Melding afgesloten';
    }
    get actionReady() {
        return (
            this.newMessage.length > 0 ||
            (!!this.files && this.files.length > 0)
        );
    }

    get progressTrail() {
        const pt = [...this.g_progressTrail.values()];
        const actionArray = [];
        for (const entry of pt) {
            const {person, operator, entryDate} = entry;
            const formatDate = moment(entryDate)
                .locale('nl')
                .format('LLL');
            let operatorName, personName, isOperator;
            // Only get 'text' actions in the chatbox
            // Bepaal of een actie van een operator komt voor links/rechts chat weergave
            if (operator) {
                operatorName = operator.name;
                isOperator = true;
            } else if (person) personName = person.name;

            actionArray.push({
                name: operatorName || personName,
                isOperator,
                formatDate,
                ...entry,
            });
        }

        return actionArray;
    }
    download(url, fileName): void {
        const downloadUrl = `${TOPDESK_URL}${url}`;
        this.a_downloadAttachment({
            downloadUrl,
            fileName,
        });
    }
    get numberOfAttachments() {
        return this.attachments.length || 0;
    }
    get attachments() {
        const attachments = [...this.progressTrail.values()].filter((entry) => {
            return !!entry.fileName;
        });
        return attachments || [];
    }
    get chatActions() {
        const textActions = [...this.progressTrail.values()].filter((entry) => {
            return !!entry.memoText || !!entry.fileName;
        });
        // Voor nu: filter HTML tags uit actions.
        // Later kijken hoe we dit veilig kunnen oplossen
        textActions.forEach((action) => {
            if (action.memoText) {
                //
                action.memoText = sanitizeHtml(action.memoText, sanitizeConfig);
            }
        });

        // const actions = textActions;
        const actions = sortDatesAsc(textActions, 'entryDate');
        actions.unshift({
            note: 'Originele melding',
            name: this.dynamicName,
            isOperator: false,
            memoText: this.trimmedRequest,
            formatDate: moment(this.callDate)
                .locale('nl')
                .format('LLL'),
            entryDate: this.callDate,
        });

        return actions || [];
    }
    closeIncidentConfirm() {
        Dialog.prompt({
            title: 'Melding afmelden',
            message: 'Weet u zeker dat u deze melding wil sluiten?',
            type: 'is-danger',
            inputAttrs: {
                placeholder: 'Reden van sluiten',
            },
            confirmText: 'Melding sluiten',
            cancelText: 'Terug',
            trapFocus: true,

            onConfirm: (value) => this.closeIncident(value),
        });
    }
    async closeIncident(closeReason?: string) {
        let message = 'Melding afgesloten door klant';
        if (closeReason) message += `</br><b>Reden:</b> ${closeReason}`;
        await this.a_sendNewAction({
            id: this.incident.id,
            actionText: message,
        });
        const closed = await this.a_closeIncident({
            id: this.incident.id,
            feedbackRating: this.incident.feedbackRating,
        });
        if (closed) {
            this.a_fetchIncidentByNumber(this.incident.number),
                this.a_fetchProgressTrail(this.incident.id);
        }
        this.checkUnsavedChanges();
    }
    clearFile() {
        this.files = [];
        // this.addFiles = null;
        // this.dropFiles = null;
    }
    rateIncident() {
        window.alert('Deze functionaliteit is nog niet geïmplementeerd');
        // this.a_RateIncident({
        //   id: this.incident.id,
        //   feedbackRating: this.selectedRating,
        //   feedbackMessage: this.feedbackMessageInput
        // });
    }
    toggleRatingActive() {
        this.ratingActive = !this.ratingActive;
    }
    deleteFile(index: number) {
        this.files.splice(index, 1);
        this.checkUnsavedChanges();
    }
    async uploadFiles(): Promise<number> {
        if (this.files) {
            const formData = new FormData();
            let res: number[];
            for (const file of this.files) {
                formData.append('file', file);
                formData.append('invisibleForCaller', 'false');
                formData.append('description', 'test description');
                const response = await this.a_uploadAttachment({
                    // file: formData,
                    file: formData,
                    id: this.incident.id,
                });
                this.clearFile();
                if (response === 403 && response === 401) return response;
            }
            return 201;
        }
    }
    async sendTextMessage(): Promise<number> {
        if (this.newMessage) {
            // Preserve line breaks
            const fmt = this.newMessage.replace(/\n/g, '</br>');
            const res = await this.a_sendNewAction({
                id: this.p_incident.id,
                actionText: fmt,
                actionInvisibleForCaller: false,
            });
            this.newMessage = '';
            return res;
        }
    }
    async sendAction() {
        this.isSending = true;

        if (await this.a_checkLoginStatus()) {
            await Promise.all([this.uploadFiles(), this.sendTextMessage()]);
            await this.a_fetchProgressTrail(this.p_incident.id);
            this.isSending = false;
            this.$forceUpdate();
            this.checkUnsavedChanges();
        }
    }

    openAttachments() {
        this.$emit('toggleatt');
    }
    updated() {
        const chatBox = this.$el.querySelector('#chatBox');
        chatBox.scrollTop = chatBox.scrollHeight;
    }

    activateDrag() {
        this.dragactive = true;
    }

    leaveDrag() {
        this.dragactive = false;
    }

    dropthatass(e) {
        e.preventDefault();
        if (e && e.dataTransfer.files) {
            for (const file of e.dataTransfer.files) {
                this.files.push(file);
            }
            this.dragactive = false;
        }
    }
}
