import {MutationTree} from 'vuex';
import {IncidentState, Incident, ProgressTrail, IncidentMap} from './types';

interface ProgressTrailOpts {
    ptMap: ProgressTrail;
    incidentId: string;
}
interface AttatchmentOpts {
    id: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    file: any;
}

export const mutations: MutationTree<IncidentState> = {
    SetUserIncidents(state, payload: IncidentMap): void {
        state.error.error_state = false;
        state.userIncidents = payload;
    },
    IncidentError(state, message?: string): void {
        state.error.error_state = true;
        state.error.error_message = message;
        // state.userIncidents = null;
        // state.newIncidentId = null;
    },
    SetActiveIncidentId(state, incidentId): void {
        state.activeIncidentId = incidentId;
    },
    SetActiveIncident(state, incident): void {
        state.activeIncident = incident;
    },
    SetActiveProgressTrail(state, progressTrail: ProgressTrail): void {
        state.activeProgressTrail = progressTrail;
    },
    StoreUpdatedIncident(state, incident: Incident): void {
        state.userIncidents.set(incident.id, incident);
    },
    StoreIncident(state, incident: Incident): void {
        state.userIncidents.set(incident.id, incident);
    },
    SetNewIncidentId(state, incidentID: string): void {
        state.newIncidentId = incidentID;
    },
    AddProgressTrail(state, opts: ProgressTrailOpts): void {
        const {ptMap, incidentId} = opts;
        state.userIncidentProgressTrails.set(incidentId, ptMap);
    },
    AddAttachment(state, opts: AttatchmentOpts): void {
        const {id, file} = opts;
        state.userIncidentAttachments.set(id, file);
    },
    ToggleDisplayClosed(state): void {
        state.displayClosedIncidents = !state.displayClosedIncidents;
    },
    ClearIncidentState(state): void {
        state.userIncidents = new Map();
        state.newIncidentId = '';
        state.userIncidents = new Map();
        state.activeProgressTrail = new Map();
        state.activeIncidentId = '';
        state.activeIncident = {request: '', briefDescription: ''};
        state.userIncidentProgressTrails = new Map();
        state.userIncidentAttachments = new Map();
    },
};
