









































import 'reflect-metadata';
import {Incident, ProgressTrail, IncidentMap} from '../store/Incidents/types';
import {User} from '../store/Auth/types';
import {Action, Getter} from 'vuex-class';
import {Component, Vue} from 'vue-property-decorator';
import IncidentCard from '../components/IncidentCard.vue';
import IncidentList from '../components/IncidentList.vue';
import Header from '../components/Header.vue';
import FilterBox from '../components/IncidentFilterBox.vue';
import LoadingSpinner from '../components/LoadingSpinner.vue';
import {hyphenateUuid} from "../store/utils";
const INCIDENT = {namespace: 'incident'};
const AUTH = {namespace: 'auth'};
const APP = {namespace: 'app'};

@Component({
    name: 'Incidents',
    components: {
        IncidentList,
        IncidentCard,
        Header,
        FilterBox,
        LoadingSpinner,
    },
})
export default class Incidents extends Vue {
    @Getter('activeIncidentId', INCIDENT)
    g_activeIncidentId: string;
    @Getter('activeIncident', INCIDENT)
    g_activeIncident: Incident;
    @Getter('userIncidents', INCIDENT)
    g_userIncidents: IncidentMap;
    @Getter('userIncidentProgressTrails', INCIDENT)
    g_userIncidentProgressTrails: IncidentMap;
    @Getter('activeProgressTrail', INCIDENT)
    g_activeProgressTrail: ProgressTrail;
    @Getter('isAccountMenuActive', APP)
    g_isAccountMenuActive: boolean;
    @Getter('user', AUTH) g_user: User;

    @Action('fetchUserAccount', AUTH)
    a_fetchUserAccount: any;
    @Action('setActivePage', APP)
    a_setActivePage: any;
    @Action('fetchUserIncidents', INCIDENT)
    a_fetchUserIncidents: any;
    @Action('fetchUserIncidentOperators', INCIDENT)
    a_fetchUserIncidentOperators: any;
    @Action('setActiveIncidentId', INCIDENT)
    a_setActiveIncidentId: any;

    isLoading = true;
    selectedIncident: Incident = null;
    newIncFormActive = false;
    displayClosed = false;
    selectedSorting = 'Laatst bijgewerkt';
    selectedStatusFilter = 'Alle';
    searchActive = false;
    searchInput: string = null;
    searchResult: IncidentMap = new Map();

    // filteredIncidents: IIncident[] = [];

    constructor() {
        super();
    }

    get loading(): boolean {
        return this.isLoading;
    }
    //Computed property accessors
    get activeIncidentId(): string {
        return this.activeIncidentId;
    }
    get incidentSelected(): boolean {
        return this.c_selectedIncident !== undefined;
    }
    get c_selectedIncident(): Incident {
        // Only try to compute selected incident if there is an incident selected
        if (this.g_activeIncidentId) {
            return this.g_userIncidents.get(this.g_activeIncidentId);
        }
    }
    get accountActive(): boolean {
        return this.g_isAccountMenuActive;
    }
    setSorting(value: string): void {
        this.selectedSorting = value;
    }
    setStatusFilter(value: string): void {
        this.selectedStatusFilter = value;
    }
    toggleDisplayClosed(value: boolean): void {
        this.displayClosed = value;

        localStorage.setItem('displayClosed', value ? 'true' : null);
    }
    //   get renderedIncidents() {}
    search(input: string): void {
        if (!input) {
            this.searchResult = this.g_userIncidents;
        } else {
            this.searchResult = new Map();
            // Get first colon index +1 to extract search value from command
            const firstColIndex = input.indexOf(':') + 1;
            const inputClean = input.toLowerCase();
            const searchVal = input
                .slice(firstColIndex)
                .toLowerCase()
                .trim();
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            for (const [key, incident] of this.g_userIncidents) {
                // Search for incident numbers
                if (inputClean.indexOf('nr:') == 0) {
                    // Clean incident numbers for easier searching
                    const regx = new RegExp('\\.', 'g');
                    const incNrClean = incident.number
                        .replace(regx, '')
                        .toLowerCase();
                    const inputNrClean = searchVal.replace(regx, '');
                    if (incNrClean.includes(inputNrClean))
                        this.searchResult.set(incident.id, incident);
                    // Search for products
                } else if (
                    inputClean.indexOf('product:') == 0 &&
                    incident.subcategory
                ) {
                    const incSubCat: string = incident.subcategory.name.toLowerCase();
                    if (incSubCat.includes(searchVal)) {
                        this.searchResult.set(incident.id, incident);
                    }
                    // Search incident title
                } else if (
                    inputClean.indexOf('titel:') == 0 &&
                    incident.briefDescription
                ) {
                    const incBdClean: string = incident.briefDescription.toLowerCase();
                    if (incBdClean.includes(searchVal)) {
                        this.searchResult.set(incident.id, incident);
                    }
                } else {
                    // Default search without command
                    if (
                        incident.number.toLowerCase().includes(searchVal) ||
                        (incident.subcategory &&
                            incident.subcategory.name
                                .toLowerCase()
                                .includes(searchVal)) ||
                        (incident.briefDescription &&
                            incident.briefDescription
                                .toLowerCase()
                                .includes(searchVal)) ||
                        (incident.request &&
                            incident.request.toLowerCase().includes(searchVal))
                    ) {
                        this.searchResult.set(incident.id, incident);
                    }
                }
                /*
			SEARCH EVERYTHING ELSE HERE
		*/
            }
        }
    }
    // mounted hook
    async mounted(): Promise<void> {
        this.displayClosed = localStorage.getItem('displayClosed') === 'true' ? true : false;
        if (!this.g_user.id) await this.a_fetchUserAccount();
        await this.a_fetchUserIncidents();

        // If the url contains a unid (incident uuid from email redirect)
        // Convert and lookup the uuid in user incidents, set incident as active
        this.a_setActivePage('Incidents');
        this.searchResult = this.g_userIncidents;
        this.isLoading = false;
    }
}
