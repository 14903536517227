var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"user-settings-page-wrapper"},[_c('HeaderBar'),(_vm.loading)?_c('LoadingSpinner',{attrs:{"active":_vm.loading}}):_vm._e(),_c('section',{staticClass:"sec-user-settings"},[_c('div',{staticClass:"inner"},[_c('section',{staticClass:"sec-user-form"},[_c('h1',{staticClass:"section-title"},[_vm._v("Persoonlijke informatie")]),_c('form',_vm._l((_vm.inputFields),function(label){return _c('div',{key:label,staticClass:"input-container"},[_c('b-field',{staticClass:"input-heading",attrs:{"horizontal":"","label":label}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.input[label].value),expression:"input[label].value"}],staticClass:"input-field",attrs:{"type":"text"},domProps:{"value":(_vm.input[label].value)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.input[label], "value", $event.target.value)},function($event){_vm.setInputField(
                                            $event.target.value,
                                            label
                                        );
                                        _vm.validateInputField(
                                            $event.target.value,
                                            label
                                        );
                                        _vm.checkUnsavedChanges();}]}})]),(
                                    !_vm.input[label].value &&
                                        _vm.input[label].required
                                )?_c('h1',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(label)+" mag niet leeg zijn")]):_vm._e(),(
                                    _vm.input[label].value &&
                                        !_vm.input[label].validation
                                )?_c('h1',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(_vm.input[label].validationText))]):_vm._e()],1)}),0),_c('div',{staticClass:"section-location"},[_c('h1',{staticClass:"section-title"},[_vm._v("Locatie")]),_c('h2',[_vm._v(_vm._s(_vm.branch))]),_c('h2',[_vm._v(_vm._s(_vm.city))])]),_c('div',{staticClass:"change-password-container"},[_c('h1',{staticClass:"section-title"},[_vm._v("Wachtwoord veranderen")]),_c('form',[_c('b-field',{attrs:{"horizontal":"","label":"Wachtwoord"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.Wachtwoord),expression:"Wachtwoord"}],staticClass:"input-field",attrs:{"autocomplete":"new-password","type":"password"},domProps:{"value":(_vm.Wachtwoord)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.Wachtwoord=$event.target.value},function($event){return _vm.checkUnsavedChanges()}]}})]),_c('b-field',{attrs:{"horizontal":"","label":"Wachtwoord bevestigen"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.WachtwoordBevestig),expression:"WachtwoordBevestig"}],staticClass:"input-field",attrs:{"type":"password","autocomplete":"new-password"},domProps:{"value":(_vm.WachtwoordBevestig)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.WachtwoordBevestig=$event.target.value},function($event){return _vm.checkUnsavedChanges()}]}})]),_c('div',{staticClass:"input-error-container"},[(!_vm.passwordVal)?_c('h2',{staticClass:"input-error"},[_vm._v("Wachtwoord voldoet niet aan de eisen")]):_vm._e(),(!_vm.passwordEq)?_c('h2',{staticClass:"input-error"},[_vm._v("Wachtwoord komt niet overeen")]):_vm._e()])],1),_c('div',{staticClass:"button-container"},[_c('button',{staticClass:"formbutton",class:{
                                        'valid': _vm.formValid,
                                        'invalid': !_vm.formValid,
                                    },attrs:{"slot":"trigger","type":"submit"},on:{"click":_vm.updateUser},slot:"trigger"},[_c('span',[_vm._v("Opslaan")])]),_c('button',{staticClass:"formbutton cancel",attrs:{"slot":"trigger"},on:{"click":_vm.cancel},slot:"trigger"},[_c('span',[_vm._v("Annuleren")])])])])])])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }