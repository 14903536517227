import {GetterTree} from 'vuex';
import {AuthState, User} from './types';
import {RootState} from '../types';

export const getters: GetterTree<AuthState, RootState> = {
    tdToken(state): string {
        return state.tdToken;
    },
    personToken(state): string {
        return state.personToken;
    },
    isLoggedIn(state): boolean {
        return !!state.personToken && !!state.tdToken;
    },
    user(state): User {
        return state.user;
    },
    userId(state): string {
        return state.user.id;
    },
    userEmail(state): string {
        return state.user.email;
    },
    xsrfToken(state): string {
        return state.xsrfToken;
    },
    sessionTimerRunning(state): boolean {
        return state.sessionTimerRunning;
    },
};
