import {ActionTree} from 'vuex';
import axios from 'axios';
import {IncidentState, Incident, NewIncident, ProgressTrail} from './types';
import {RootState} from '../types';
import { state as AuthState} from '../Auth/index';
import {AxiosRequestConfig} from 'axios';
import fileDownload from 'js-file-download';
import {ToastProgrammatic as Toast} from 'buefy';
import {LogoutReason} from '../Auth/types';

export const TOPDESK_URL = "https://axians-ba.topdesk.net"
const baseUrl = `${TOPDESK_URL}/tas/api`;

interface NewAction {
    id: string;
    actionText: string;
}
interface NewAttachment {
    id: string;
    file: FormData;
    type: string;
}
interface RatingOpts {
    id: string;
    feedbackRating: number;
    feedbackMessage?: string;
}

export const actions: ActionTree<IncidentState, RootState> = {
    setUserIncidents({commit}, incidents: Map<string, Incident>): void {
        commit('SetUserIncidents', incidents);
    },
    setActiveIncidentId({commit}, id: string): void {
        commit('SetActiveIncidentId', id);
    },
    setActiveIncident({commit}, incident: Incident): void {
        commit('SetActiveIncident', incident);
    },
    storeUpdatedIncident({commit}, incident: Incident): void {
        commit('StoreUpdatedIncident', incident);
    },
    setNewIncident({commit}, newIncident: NewIncident): void {
        // Add callerLookup to newincident from the logged-in user
        newIncident.callerLookup = {id: newIncident.userId};

        commit('SetNewIncident', newIncident);
    },
    setActiveProgressTrail({commit}, pt: ProgressTrail): void {
        commit('SetActiveProgressTrail', pt);
    },
    async fetchUserIncidents({commit, dispatch}): Promise<void> {
        try {
            await dispatch('auth/checkLoginStatus', {}, {root: true});
            const response = await axios.get(
                `/api/incidents`,
                {
                    headers: {
                        'x-bisupport-shield': 'true',
                        Pragma: 'no-cache',
                        'Cache-Control': 'no-cache'
                    },
                }
            );
            if (response.data) {
                const incidentMap: Map<string, Incident> = new Map();
                for (const incident of response.data.data) {
                    incidentMap.set(incident.id, incident);
                }
                commit('SetUserIncidents', incidentMap);
            }
        } catch (e) {
            commit('IncidentError', `Error retrieving Incidents: ${e}`);
        }
    },
    async fetchIncidentById({commit}, id: string): Promise<void> {
        try {
            const response = await axios.get(`/api/incidents/id/${id}`, {
                headers: {
                    'x-bisupport-shield': 'true',
                    Pragma: 'no-cache',
                    'Cache-Control': 'no-cache'
                },
            });
            if (response && response.data) {
                commit('SetActiveIncident', response.data.data);
            }
        } catch (e) {
            commit('IncidentError', `Error fetching incident by id: ${e}`);
        }
    },
    async fetchIncidentByNumber(
        {commit, dispatch},
        number: string
    ): Promise<void> {
        try {
            await dispatch('auth/checkLoginStatus', {}, {root: true});
            const response = await axios.get(
                `/api/incidents/number/${number}`,
                {
                    headers: {
                        'x-bisupport-shield': 'true',
                        Pragma: 'no-cache',
                        'Cache-Control': 'no-cache'
                    },
                }
            );
            if (response && response.data) {
                commit('SetActiveIncident', response.data.data);
            }
        } catch (e) {
            commit('IncidentError', `Error fetching incident by number: ${e}`);
        }
    },
    async fetchProgressTrail(
        {commit, dispatch},
        incidentId: string
    ): Promise<void> {
        try {
            await dispatch('auth/checkLoginStatus', {}, {root: true});

            const response = await axios.get(
                `/api/incidents/${incidentId}/progresstrail`,
                {
                    headers: {
                    'x-bisupport-shield': 'true',
                    Pragma: 'no-cache',
                    'Cache-Control': 'no-cache'
                    },
                }
            );

            const ptMap: ProgressTrail = new Map();

            if (typeof response.data.data === 'object') {
                for (const pt of response.data.data) {
                    ptMap.set(pt.id, pt);
                }

                commit('SetActiveProgressTrail', ptMap);
                commit('AddProgressTrail', {ptMap, incidentId});
            }
        } catch (e) {
            commit('IncidentError', `Error fetching progress trail: ${e}`);
        }
    },
    toggleDisplayClosed({commit}): void {
        commit('ToggleDisplayClosed');
    },
    async storeIncident(
        {commit, dispatch},
        info: {newIncident: NewIncident; userId: string}
    ): Promise<void> {
        try {
            const {newIncident, userId} = info;
            // Set default values for incident form
            newIncident.callerLookup = {id: userId};
            newIncident.operatorGroup = {
                id: 'b5d531e8-3688-4a2b-8b19-7136a468de00',
            };
            newIncident.object = {id: 'f7788190-02c0-4f62-95bf-1ff9b5d1446f'};
            // newIncident.sla = {id: 'c288fa65-d649-48a0-8424-10e73364fe55'};
            newIncident.entryType = {
                id: '58edd1fb-76e7-5027-aeb6-264c3f2a393a',
            };

            // eslint-disable-next-line no-console
            const response = await axios.post('/api/incidents', newIncident, {
                headers: {'x-bisupport-shield': 'true'},
            });

            if (response.data.data) {
                commit('SetNewIncidentId', response.data.data.id);
                commit('StoreIncident', response.data.data);
                Toast.open({
                    message: 'Melding aangemaakt!',
                    type: 'is-success',
                    position: 'is-top',
                    duration: 5000,
                });
            }
            // await dispatch('fetchIncidentByNumber', response.data.data.number);
            // dispatch('fetchUserIncidents');
        } catch (e) {
            
            commit('IncidentError', `Error storing new Incident: ${e}`);
            Toast.open({
                message: 'Melding aanmaken mislukt!',
                type: 'is-danger',
                position: 'is-top',
                duration: 5000,
            });
        }
    },
    async closeIncident(
        {commit, dispatch},
        rating: RatingOpts
    ): Promise<boolean> {
        try {
            const {id, feedbackRating} = rating;
            const result = await axios.patch(
                `/api/incidents/${id}/close`,
                // Send rating to determine closing status
                {feedbackRating},
                {
                    headers: {'x-bisupport-shield': 'true'},
                }
            );
            if (result.data.data) {
                commit('StoreUpdatedIncident', result.data.data);
                Toast.open({
                    type: 'is-success',
                    position: 'is-top',
                    message: 'Melding afgesloten',
                });
                return true;
            }
        } catch (e) {
            commit('IncidentError', `Error closing incident ${e}`);
        }
    },
    async rateIncident({commit, dispatch}, rating: RatingOpts): Promise<void> {
        try {
            const {id, feedbackRating, feedbackMessage} = rating;
            const result = await axios.patch(
                `/api/incidents/${id}/rate`,
                {feedbackRating, feedbackMessage},
                {
                    headers: {'x-bisupport-shield': 'true'},
                }
            );
            if (result.data.data) {
                commit('StoreUpdatedIncident', result.data.data);
            }
        } catch (e) {
            if (
                e &&
                e.response &&
                (e.response.status == 401 || e.response.status == 403)
            ) {
                dispatch('logout', LogoutReason.SESSIONEXPIRED);
            }
            commit('IncidentError', `Error creating incident: ${e}`);
        }
    },
    async sendNewAction(
        {commit, dispatch},
        newAction: NewAction
    ): Promise<void> {
        try {
            const {id, actionText} = newAction;
            const response = await axios.put(
                `/api/incidents/${id}/action`,
                {actionText},
                {
                    headers: {'x-bisupport-shield': 'true'},
                }
            );
        } catch (e) {
            commit('IncidentError', `Error sending action ${e}`);
        }
    },
    async postDefaultActions({commit}, incidentId: string): Promise<void> {
        try {
            await axios.put(
                `/api/incidents/${incidentId}/action/defaults`,
                {},
                {
                    headers: {'x-bisupport-shield': 'true'},
                }
            );
        } catch (e) {
            commit('IncidentError', `Error inserting impact analysis ${e}`);
        }
    },
    async uploadAttachment(
        {commit, dispatch},
        newFile: NewAttachment
    ): Promise<void> {
        try {
            const {file, id} = newFile;
            const headers: AxiosRequestConfig = {
                headers: {
                    // eslint-disable-next-line quote-props
                    authorization: `TOKEN id="${AuthState.tdToken}"`,
                    'x-bisupport-shield': 'true',
                    'Content-Type': 'multipart/form-data',
                },
            };
            const result = await axios.post(
                `${baseUrl}/incidents/id/${id}/attachments`,
                file,
                headers
            );
            // const result = await axios.post(
            //     `/api/incidents/${id}/attachments`,
            //     file,
            //     headers
            // );
        } catch (e) {
            commit('IncidentError', `Error uploading attachment ${e}`);
        }
    },
    async downloadAttachment(
        {commit, dispatch},
        {downloadUrl, fileName}
    ): Promise<void> {
        try {
            await dispatch('auth/checkLoginStatus', {}, {root: true});
            const headers: AxiosRequestConfig = {
                headers: {
                    authorization: `TOKEN id="${AuthState.tdToken}"`,
                        Pragma: 'no-cache',
                        'Cache-Control': 'no-cache'
                },
                responseType: 'blob',
            };
            const response = await axios.get(downloadUrl, headers);
            fileDownload(response.data, fileName);
        } catch (e) {
            commit('IncidentError', `Error downloading attachment ${e}`);
        }
    },
};
