// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../public/img/Background-create2.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".create-incident-page-wrapper[data-v-3349b161]{height:100vh;width:100vw;overflow:hidden;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-size:cover;background-position:top}section.sec-create-incident[data-v-3349b161]{width:49vw;height:auto;background-color:#fff;border-radius:10px;display:block;position:relative;margin:0 auto;padding:5vh;margin-top:10vh;left:0;display:-webkit-box;display:-ms-flexbox;display:flex;z-index:1;-webkit-box-shadow:0 3px 6px hsla(0,0%,39.2%,.35);box-shadow:0 3px 6px hsla(0,0%,39.2%,.35);-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-transition:.5s;transition:.5s}section.intro[data-v-3349b161]{-ms-flex-line-pack:center;align-content:center;display:-webkit-box;display:-ms-flexbox;display:flex;position:relative;top:3vh}.title[data-v-3349b161]{font-size:60px;color:red;font-weight:400;text-align:center;margin-bottom:0;-webkit-user-select:none;-ms-user-select:none;-moz-user-select:none;user-select:none}.background[data-v-3349b161]{display:block;text-indent:-9999px;width:100vw;height:100vh;z-index:-1}.hl[data-v-3349b161]{border-bottom:4px solid #000;width:90vw;text-align:center;font-family:Vinci Rounded Light,Arial,sans-serif;color:#00f}.subtitle[data-v-3349b161]{text-align:right;font-size:25px;color:#000;font-weight:400;color:#00f}.title-wrapper[data-v-3349b161]{width:auto;margin:0 auto}@media only screen and (max-width:800px){section.sec-create-incident[data-v-3349b161]{width:90%}}@media only screen and (max-width:600px){section.sec-create-incident[data-v-3349b161]{width:100vw;height:91vh;top:0;padding:2vh;margin:0}.create-incident-page-wrapper[data-v-3349b161]{overflow-y:scroll}}", ""]);
// Exports
module.exports = exports;
