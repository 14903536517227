













































import {
    Incident,
    IncidentMap,
    ProcessingStatus,
    IncidentSortingOptions,
} from '../store/Incidents/types';
import {Action, Getter} from 'vuex-class';
import {Component, Vue, Prop} from 'vue-property-decorator';
const INCIDENT = {namespace: 'incident'};
// const APP = {namespace: 'app'};

@Component({
    name: 'IncidentFilterBox',
})
export default class IncidentFilterBox extends Vue {
    @Prop(Boolean) displayClosed;

    @Getter('activeIncident', INCIDENT)
    g_activeIncident: Incident;
    @Getter('userIncidents', INCIDENT)
    g_userIncidents: IncidentMap;
    @Getter('displayClosedIncidents', INCIDENT) g_displayClosed: boolean;

    @Action('', INCIDENT)
    a_toggleDisplayClosed: any;

    sortingBox1: IncidentSortingOptions[] = [
        'Laatst bijgewerkt',
        'Aanmelddatum nieuw-oud',
        'Aanmelddatum oud-nieuw',
        'Afmelddatum nieuw-oud',
        'Afmelddatum oud-nieuw',
    ];
    filterBox2: ProcessingStatus[] = [
        'Alle',
        'Nieuw',
        'In behandeling',
        'Wacht op info klant',
        'Gereed',
        'Afgemeld',
    ];

    
    selectedFilter1: IncidentSortingOptions = 'Laatst bijgewerkt';
    selectedFilter2: ProcessingStatus = 'Alle';

    get dpClosed() {
        return localStorage.getItem("displayClosed");
    }

    selectFilterValue1(value: IncidentSortingOptions) {
        this.selectedFilter1 = value;
        this.$emit('select-sorting', this.selectedFilter1);
    }
    selectFilterValue2(value: ProcessingStatus) {
        this.selectedFilter2 = value;
        this.$emit('select-status-filter', this.selectedFilter2);
    }
    toggleDisplayClosed(value: boolean) {
        this.$emit('toggle-display-closed', value);
    }
    navCreate() {
        this.$router.push('/melding/aanmaken');
    }
}
