












import {Action, Getter} from 'vuex-class';
import {Component, Vue} from 'vue-property-decorator';
import NewIncidentForm from '../components/NewIncidentForm.vue';
import Header from '../components/Header.vue';
import {User} from '../store/Auth/types';
import {Incident} from '../store/Incidents/types';
import {DialogProgrammatic as Dialog} from 'buefy';
// import router from '../router/index';
const INCIDENT = {namespace: 'incident'};
const AUTH = {namespace: 'auth'};
const APP = {namespace: 'app'};

Component.registerHooks(['beforeRouteLeave']);

@Component({
    name: 'CreateIncident',
    components: {NewIncidentForm, Header},
})
export default class CreateIncident extends Vue {
    reference: {number: string; description: string};

    @Action('setActivePage', APP) a_setActivePage: any;
    @Action('fetchUserAccount', AUTH) a_fetchUserAccount: any;
    @Action('fetchUserIncidents', INCIDENT) a_fetchUserIncidents: any;
    @Action('navigationGuard', APP) a_navigationGuard: any;

    @Getter('user', AUTH) g_user: User;
    @Getter('userIncidents', INCIDENT) g_userIncidents: Map<string, Incident>;
    @Getter('unsavedChanges', APP) g_unsavedChanges: boolean;

    constructor() {
        super();
    }

    async beforeRouteLeave(to, from, next) {
        // If saved changes, guard navigation
        // Exclude / for logout routes
        if (this.g_unsavedChanges && to.path !== '/') {
            await this.a_navigationGuard(next);
        } else {
            next();
        }
    }
    navOverview() {
        this.$router.push('/melding/overzicht');
    }
    async mounted(): Promise<void> {
        if (!this.g_user.id) await this.a_fetchUserAccount();
        if (this.g_userIncidents.size == 0) await this.a_fetchUserIncidents();

        this.a_setActivePage('CreateIncident');
    }
}
