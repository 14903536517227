
































import 'reflect-metadata';
import {Incident} from '../store/Incidents/types';
import {Page} from '../store/App/types';
import {Action, Getter} from 'vuex-class';
import {Component, Vue, Prop, Emit} from 'vue-property-decorator';
import router from '../router/index';
import sanitizeHtml from 'sanitize-html';

import moment from 'moment';

const INCIDENT = {namespace: 'incident'};
// const AUTH = {namespace: 'auth'};
const APP = {namespace: 'app'};

const sanitizeConfig = {
    allowedTags: ['i', 'em', 'b', 'strong', 'br'],
    allowedAttributes: [],
    selfClosing: ['br'],
};

@Component({
    name: 'IncidentCard',
    components: {},
})
export default class IncidentCard extends Vue {
    @Prop() readonly incident: Incident;
    @Getter('activeIncidentId', INCIDENT) g_activeIncidentId: string;
    @Getter('activePage', APP) g_activePage: Page;
    @Action('setActiveIncident', INCIDENT)
    a_setActiveIncident: any;

    constructor() {
        super();
    }
    get actionRequired(): boolean {
        if (this.incident && this.incident.processingStatus) {
            return (
                this.incident.processingStatus.name === 'Wacht op info klant'
            );
        } else return false;
    }
    get isPrioOne(): boolean {
        if (this.incident && this.incident.processingStatus) {
            return this.incident.priority.name === 'Prioriteit 1';
        } else return false;
    }
    get upperBd() {
        if (this.incident && this.incident.briefDescription) {
            const upperCase = sanitizeHtml(
                this.incident.briefDescription,
                sanitizeConfig
            );
            return upperCase.length > 35
                ? upperCase.slice(0, 35) + '...'
                : upperCase;
        } else return '';
    }
    get status() {
        if (this.incident && this.incident.processingStatus) {
            return this.incident.processingStatus;
        } else return '';
    }
    get icon() {
        const status = this.incident.processingStatus.name;
        if (status === 'Nieuw') return 'schedule';
        else if (status === 'Toegewezen') return 'person'
        else if (status === 'Afgewezen') return 'clear';
        else if (status === 'Wacht op info klant')
            return 'notifications_active';
        else if (status === 'In behandeling') return 'double_arrow';
        else if (status === 'Gereed') return 'done';
        else if (status === 'Afgemeld') return 'done_all';
    }
    // Computed properties
    get isActive() {
        return this.incident.id === this.g_activeIncidentId ? true : false;
    }
    get date() {
        return moment(this.incident.modificationDate)
            .locale('nl')
            .format('LLL');
    }
    get isLanding() {
        return this.g_activePage === 'Landing';
    }
    get operator() {
        if (!this.incident.operator) return 'Niet toegewezen';
        else return this.incident.operator.name;
    }
    get priority() {
        if (!this.incident.priority.name) return 'Niet toegewezen';
        else return this.incident.priority.name.split(' ')[1];
    }

    @Emit(`selectIncident`)
    setIncidentActive() {
        this.a_setActiveIncident(this.incident);
        localStorage.setItem('activeIncident', this.incident.number);
        router.push('/melding/detail');
    }
}
