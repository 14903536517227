


















import {Component, Vue, Prop} from 'vue-property-decorator';
import Loading from 'vue-loading-overlay';

@Component({
    name: 'LoadingSpinner', 
    components: {
        Loading,
    },
} as any)
export default class LoadingSpinner extends Vue {
    @Prop(Boolean) active: boolean;

    constructor() {
        super();
    }
    loadingText = 'Pagina is aan het laden';
    spinnerColor = '#a20067';
    spinnerBackgroundColor = '#fff';
    spinnerHeight = 60;
    spinnerWidth = 60;
    spinnerBackgroundOpacity = 1;
    spinnerLoader = 'bars';
    spinnerZ = 998;
}
