import {IUser} from '../../../../api/models/user';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface User extends IUser {}

export interface AuthState {
    auth_status: string;
    tdToken: string;
    personToken: string;
    isLoggedIn: boolean;
    user: User;
    error: boolean;
    xsrfToken: string;
    sessionTimerRunning: boolean;
    sessionExpiry?: string;
}

export enum LogoutReason {
    LOGOUT,
    SESSIONEXPIRED,
    AUTHFAIL,
}
