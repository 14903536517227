

















import {Action, Getter} from 'vuex-class';
import {Component, Vue} from 'vue-property-decorator';
const APP = {namespace: 'app'};
const INCIDENT = {namespace: 'incident'};

@Component({name: 'NotificationBell'})
export default class NotificationBell extends Vue {
    @Action('toggleNotifications', APP) a_toggleNotifications: any;
    @Getter('actionIncidentCount', INCIDENT) g_actionIncidentCount: number;

    get actionsPresent() {
        return this.g_actionIncidentCount > 0;
    }

    toggleNotifications() {
        this.a_toggleNotifications();
    }
}
