import {GetterTree} from 'vuex';
import {AppState} from './types';
import {RootState} from '../types';

export const getters: GetterTree<AppState, RootState> = {
    incidentsActive(state): boolean {
        return state.isIncidentsActive;
    },
    isAccountMenuActive(state): boolean {
        return state.isAccountMenuActive;
    },
    activePage(state): string {
        return state.activePage;
    },
    unsavedChanges(state): boolean {
        return state.unsavedChanges;
    },
};
