import {ActionTree} from 'vuex';
import axios from 'axios';
import {AuthState, LogoutReason} from './types';
import {state} from './index';
import {RootState} from '../types';
import router from '../../router/index';
import Cookie from 'js-cookie';
import {ToastProgrammatic as Toast} from 'buefy';
import {sleep} from '../utils';

export const actions: ActionTree<AuthState, RootState> = {
    async login({commit, dispatch}, user): Promise<void> {
        try {
            const options = {
                auth: {
                    username: user.username,
                    password: user.password,
                },
            };

            // axios.defaults.withCredentials = true;
            const login = await axios.post(
                `/api/auth/login`,
                {xsrf: user.xsrf},
                options
            );
            
            const xsrf = login.data.data.xsrfToken;
            commit('SetXsrfToken', xsrf);

            axios.defaults.headers.common['X-XSRF-TOKEN'] = state.xsrfToken;
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const {
                token,
                tdToken: userData,
                // sessionExpiryDate,
            } = login.data.data;
            axios.defaults.headers.common['x-bisupport-shield'] = `true`;

            commit('auth_success', {
                personToken: token,
                tdToken: userData.token,
            });
            // await dispatch('fetchUserAccount');
            router.push('/landing');
            dispatch('startSessionTimer');
        } catch (e) {
            if (e.response && e.response.status === 401) {
                dispatch('logout', LogoutReason.AUTHFAIL);
            } else {
                Toast.open({
                    message: 'Er is iets mis met de applicatie',
                    type: 'is-danger',
                    position: 'is-top',
                    duration: 5000,
                });
            }
            commit('auth_error', e);
        }
    },

    async refreshAccessToken({commit, dispatch}): Promise<number> {
        try {
            const result = await axios.post(
                '/api/auth/refreshtoken',
                {},
                {headers: {'x-bisupport-shield': 'true'}}
            );
            dispatch('startSessionTimer');
            return result.status;
        } catch (e) {
            commit('auth_error', e);
            return null;
        }
    },

    async startSessionTimer({dispatch}): Promise<void> {
        // Ververs token iedere 5 minuten
        await sleep(300);
        dispatch('refreshAccessToken');
    },

    async getCSRF(): Promise<void> {
        try {
            await axios.post('/api/auth/csrf', {});
            axios.defaults.headers['X-XSRF-TOKEN'] = state.xsrfToken;
        } catch (e) {}
    },
    
    async checkLoginStatus({commit, dispatch}): Promise<boolean> {
        const accessToken = Cookie.get('bisupport-session-header');
        const tdToken = Cookie.get('bisupport-token-header');

        // Check if auth cookies are present
        const loggedIn = !!accessToken && !!tdToken;

        commit('SetLoggedIn', loggedIn);

        // If tokens are present, continue
        if (loggedIn) {
            return true;
        }

        // Logout if topdesk token is missing
        if (!tdToken) {
            await dispatch('logout', LogoutReason.SESSIONEXPIRED);
            return false;
        }

        // Attempt to refresh access token if missing
        if (!accessToken) {
            const refreshed = await dispatch('refreshAccessToken');
            // Logout if token cant be refreshed
            if (!refreshed) {
                dispatch('logout', LogoutReason.SESSIONEXPIRED);
                return false;
            }
            return true;
        }
    },
    async fetchUserAccount({commit, dispatch}): Promise<void> {
        try {
            await dispatch('checkLoginStatus');
            const response = await axios.get('/api/account', {
                headers: {
                    'x-bisupport-shield': 'true',
                    Pragma: 'no-cache',
                    'Cache-Control': 'no-cache'
                },
                
            });
            if (response.data) {
                const {account, tdToken} = response.data.data;
                commit('SetUserDetails', account);
                // zorg dat er een geldig td sessie token beschikbaar is in store
                commit('SetTdToken', tdToken);
            }
        } catch (e) {
            commit('auth_error', e);
        }
    },
    async updateUser({commit, dispatch}, person): Promise<void> {
        try {
            const response = await axios.patch(`/api/account`, person);

            if (response.data) {
                commit('SetUserDetails', response.data.data);
            }

            await dispatch('fetchUserAccount');

            Toast.open({
                message: 'Opgeslagen',
                type: 'is-success',
                position: 'is-top',
                duration: 5000,
            });

        } catch (e) {
            Toast.open({
                message: 'Opslaan mislukt',
                type: 'is-danger',
                position: 'is-top',
                duration: 5000,
            });
            commit('auth_error', e);
        }
    },
    async logout({commit}, reason: LogoutReason): Promise<void> {
        commit('incident/ClearIncidentState', null, {root: true});
        commit('ClearAuthState');
        commit('logout');
        try {
            if (Cookie.get('bisupport-session-header')) {
                await axios.post(
                    '/api/auth/logout',
                    {},
                    {
                        headers: {'x-bisupport-shield': 'true'},
                    }
                );
            }
        } catch (e) {
        } finally {
            // Clear niet-hhtponly cookies

            let message;
            let type;

            switch (reason) {
                case LogoutReason.AUTHFAIL:
                    message = 'Inloggen mislukt, controleer uw login gegevens';
                    type = 'is-danger';
                    break;
                case LogoutReason.LOGOUT:
                    message = 'Uitgelogd';
                    type = 'is-success';
                    break;
                case LogoutReason.SESSIONEXPIRED:
                    message =
                        'Uw sessie is verlopen, log alstublieft opnieuw in';
                    type = 'is-danger';
                    break;
                default:
                    message = 'Uitgelogd';
                    type = 'is-success';
                    break;
            }

            Toast.open({
                message,
                type,
                position: 'is-top',
                duration: 5000,
            });

            router.push(`/`);
        }
    },
    setUserDetails({commit}, user): void {
        commit('SetUserDetails', user);
    },
};
